import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'solutions-additional-services',
  templateUrl: './solutions-additional-services.component.html',
  styleUrls: ['./solutions-additional-services.component.scss']
})
export class SolutionsAdditionalServicesComponent implements OnInit {

  public blemButton = 'https://firebasestorage.googleapis.com/v0/b/ledu-web-page.appspot.com/o/new-design%2Fsolutions-services%2Fboto%CC%81n%20blem.png?alt=media&token=f18d522e-f40b-44d9-8708-d7eb9c4d681c'

  public aditionalServices = [
    {
      name: 'Reporte de actividad',
      caption: '¿Te gustaría recibir en tu correo electrónico la actividad que tiene tu sistema de alarma?',
      description: 'Con el reporte de actividad, recibirás mensual o semanalmente el informe cronológico de aperturas y cierres de tu equipo.',
      price: 'free'
    },
    {
      name: 'Aplicación Smartpanics',
      caption: 'Aplicación para envío de señales de Emergencia y/o asistencia a la Central de Monitoreo, respuesta en eventos y canalización de solicitudes.',
      description: 'Incluye consulta de cuentas de Monitoreo, eventos en tiempo real, Notificaciones PUSH de eventos de Prioridad y estatus de reportes de Servicio.',
      price: 'free'
    },
    {
      name: 'Acceso web',
      caption: '',
      description: 'Solicita tu acceso web y obtén los beneficios de revisar tu alarma a tiempo real, además de consultar el historial de eventos ocurridos, obtener la base de datos de tu personal autorizado y el estatus de reportes.',
      price: '100'
    },
    {
      name: 'Control de horarios',
      caption: '',
      description: 'Recibe una notificación cuando ocurran aperturas y/o cierres fuera de horarios establecidos por el Usuario.',
      price: '100'
    },
    {
      name: 'Video verificación y Revisión de enlaces CCTV',
      caption: '',
      description: 'Revisión diaria de enlaces de CCTV y revisión de cámaras en eventos de emergencia.',
      price: '100',
      extra: '· No incluye reportes de servicio.'
    },
    {
      name: 'Rondines especiales',
      caption: '',
      description: 'Presencia de Guardia de Seguridad en Unidad de LEDU, incluye verificación por el exterior del domicilio, permanencia por 15 minutos, reporte de incidencias a Central de Monitoreo y elaboración de Reporte físico.',
      price: '200'
    },
  ]

  constructor() { }

  ngOnInit() {
  }

}
